import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AngularFireModule} from '@angular/fire/compat';
import {FormsModule} from '@angular/forms';
import {environment} from '../environments/environment.prod';
import { HttpClientModule } from '@angular/common/http';
import {TimeagoModule} from 'ngx-timeago';
import {CommonModule} from '@angular/common';
import {BackButtonDisableModule} from 'angular-disable-browser-back-button';
import {TempService} from './services/temp.service';
import {QueryService} from './services/query.service';
import {PlatformDetectService} from './services/platform-detect.service';
import {SeeyouComponent} from "./component/seeyou/seeyou.component";

 @NgModule({
  declarations: [AppComponent, SeeyouComponent],
  imports: [BrowserModule, IonicModule.forRoot(), CommonModule,FormsModule, AngularFireModule.initializeApp(environment.firebase),
    HttpClientModule,    TimeagoModule.forRoot(), AppRoutingModule,
    BackButtonDisableModule.forRoot()
  ],
   providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  TempService, QueryService, PlatformDetectService],
  bootstrap: [AppComponent]
})

export class AppModule {}


