import {Component} from '@angular/core';
import {AlertController, LoadingController, ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {App} from '@capacitor/app';
import {MultiService} from './multi/multi.service';
import {Network} from '@capacitor/network';
import { PlatformLocation} from '@angular/common';
import {MultiEnum} from "./multi/multi-enum";
import {getHostName} from "./multi/multiFunctions";
import {QueryService} from "./services/query.service";
import {StorageService} from "./services/storage.service";
import { register } from 'swiper/element/bundle';

register();


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
   })

export class AppComponent {

  subscription: Subscription;
  currentRoute = '';
  backPressCounter = 0;


  constructor(private multiService: MultiService,
              private modalController: ModalController,
              private platformLocation: PlatformLocation,
              private loadingController: LoadingController,
              private alertController: AlertController,
              private queryService: QueryService,
              private storageService: StorageService) {

    console.log('app component start entered!')


    App.addListener('backButton', ({canGoBack}) => {
      console.log('can go back '  + canGoBack);
      if (this.currentRoute === '/tabs/Store' || '/tabs/Home' || '/tabs/Profile' ||
        '/tabs/Profile/product-list' || '/tabs/Profile/product-list/add-product'){
         this.backPressCounter += 1;
        if (this.backPressCounter >= 2){
          this.multiService.exitApp();
        }
      }
    });

    this.platformLocation.onPopState((f) => {
      let popUpDetect = false;
      const p1 = new Promise((resolve, reject) => {
      this.alertController.getTop().then(async t => {
        const alertCtrl = await this.alertController.getTop();
        if (alertCtrl) {
          popUpDetect = true;
        await  alertCtrl.dismiss();
          resolve(true);
          return;
        }else {
          resolve(true);
        }
      });

      this.loadingController.getTop().then(async t => {
        const loadingCtrl = await this.loadingController.getTop();
        if (loadingCtrl){
          popUpDetect = true;
          await loadingCtrl.dismiss();
          resolve(true);
          return;
        }else {
          resolve(true);
        }
      });

         this.modalController.getTop().then(async t => {
            const modal = await this.modalController.getTop();
           if (modal){
             popUpDetect = true;
             await modal.dismiss();
             resolve(true);
             return;
           }else {
             resolve(true);
           }
        });

      });


      p1.then(async t => {





        const closeWindow = () => {


          window.location.replace('/seeyou')

          history.replaceState(null, null, '/seeyou')
          history.pushState(null, null, '/seeyou')


        }

      if(!popUpDetect){
         const locate = location.pathname;
         setTimeout(() => {
          switch (locate){
            case '/tabs/Store/all-main-category/Categories':
              this.multiService.setUrl({url: 'tabs/Store/all-main-category'}).then();
              break;

            case '/tabs/Store/all-main-category':
              this.multiService.setUrl({url: 'tabs/Store'}).then();
              break;

            case '/tabs/Profile/product-list':
              this.multiService.setUrl({url: 'tabs/Profile'}).then();
              break;

            case '/tabs/Profile/product-list/add-product':
              this.multiService.setUrl({url: '/tabs/Profile/product-list'}).then();
              break;

            case '/admin':
              this.multiService.setUrl({url: '/tabs/Profile'}).then();
              break;


            case '/tabs/Store':
              this.multiService.alertService('exit')
                .then(t => {



                  if (t){
                     closeWindow()
                  }
                })
              break;


            case '/tabs/Home':
              this.multiService.alertService('exit')
                .then(t => {
                  if (t){
                    closeWindow()
                  }
                })
              break;

            case '/tabs/Profile':
              this.multiService.alertService('exit')
                .then(t => {
                  if (t){
                    closeWindow()
                  }
                })
              break

            case '/tabs/auth':
              this.multiService.alertService('exit')
                .then(t => {
                  if (t){
                    closeWindow()
                  }
                })
              break



          }
        });
      }
      });

    });

    Network.addListener('networkStatusChange', status => {
       this.multiService.toastService({message: 'Internet connection ' + (status.connected ? 'has been restored' : 'disconnected'), position: 'bottom'})
        .then();
    });

    // const logCurrentNetworkStatus = async () => {
    //   const status = await Network.getStatus();
    //   console.log('Network status:', status);
    // };
    //
    // logCurrentNetworkStatus().then();


    this.storageService.getStorageData(MultiEnum.two)
      .then((userData: any) => {

        if (userData && userData.id) {
          this.queryService.getDocWithSnapshot(`Users/${getHostName()}Users/${userData.id}`)
            .subscribe((s1: any) => {
              console.log(s1)
              delete s1.password;
              console.log(s1)
              this.storageService.setStorage(MultiEnum.two, s1)
                .then(t => {
                  // this.multiService.loadingControl(false);
                  // console.log(MultiEnum.two, s1)
                  // if (!this.firstLoad || !t){
                  //   this.multiService.setUrl({url: '/tabs/Profile'});
                  //   this.firstLoad = true
                  // }
                });
            });
        }

      })

    console.log('App component passed all method!')

  }



}

