import {Injectable} from '@angular/core';
import {Preferences} from '@capacitor/preferences';
import {Storage} from '@ionic/storage-angular';
import {PlatformDetectService} from './platform-detect.service';
import {MultiEnum} from '../multi/multi-enum';
import {SiteInformationModel} from '../multi/multi-model';
import {QueryService} from './query.service';
import {MultiService} from '../multi/multi.service';
import {AdMob, BannerAdOptions, BannerAdPosition, BannerAdSize} from '@capacitor-community/admob';
import {getHostName} from '../multi/multiFunctions';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private platformDetect: PlatformDetectService,
              private queryService: QueryService,
              private multiService: MultiService) {}


  setStorage(storageName, setData) {

    const store = new Storage();

    return new Promise(resolve => {
      this.platformDetect.platformCheck().then(checkedPlatform => {
        switch (checkedPlatform) {
          case 'android':
            Preferences.set({key: storageName, value: JSON.stringify(setData)}).then(data => {
              resolve(data);
            });
            break;

          case 'ios':
            Preferences.set({key: storageName, value: JSON.stringify(setData)}).then(data => {
              resolve(data);
            });
            break;

          case 'mobileweb':
            store.create().then(t1 => store.set(storageName, setData))
              .then(data => {
                resolve(data);
              });
            break;

          case 'desktop':
            store.create().then(t1 => store.set(storageName, setData))
              .then(data => {
                resolve(data);
              });
        }
      });
    });

  }


  getStorageData(storageName) {

    const store = new Storage();

    return new Promise(resolver => {

      this.platformDetect.platformCheck().then(checkedPlatform => {

        console.log(checkedPlatform)

        switch (checkedPlatform) {
          case 'android':
            Preferences.get({key: storageName}).then(data => {
              resolver(JSON.parse(data.value));
            });
            break;
          case 'ios':
            Preferences.get({key: storageName}).then(data => {
              resolver(JSON.parse(data.value));
            });
            break;

          case 'mobileweb':
            store.create().then(t1 => {
              store.get(storageName)
                .then(data => {
                    resolver(data);
                  }
                ).catch(c => console.log(c));
            });
            break;

          case 'desktop':
            store.create().then(t1 => {
              store.get(storageName)
                .then(data => {
                    resolver(data);
                  }
                ).catch(c => console.log(c));
            });
            break;
        }
      });
    });
  }

  async allStorageData() {

    console.log('all storage function in storage service')

    return new Promise(resolve => {

       const getStorage = (siteInformation) => {

        this.getStorageData(MultiEnum.two).then((userData: any) => {
          if (userData) {
            siteInformation.userData = userData;
            resolve(siteInformation);
          } else {
            resolve(siteInformation);
          }
        }).catch(() => resolve(siteInformation));


      };


      this.getStorageData(MultiEnum.one).then((siteInformation: SiteInformationModel) => {
        console.log(siteInformation);
        if (siteInformation) {
          getStorage(siteInformation);
        }else {
          // this.firstLoad().then(async t => {
          //   getStorage(t);
          // });
        }
      });
    });
  }


 // async firstLoad() {
 //
 //
 //    console.log(getHostName());
 //    return new Promise(resolve => {
 //
 //    this.queryService.getDocWithSnapshot(MultiEnum.one)
 //      .subscribe((siteInformation: SiteInformationModel) => {
 //        console.log(siteInformation)
 //        if (siteInformation) {
 //          this.platformDetect.platformCheck().then(checkedPlatform => {
 //             Object.assign(siteInformation, {hostName:  getHostName(), deviceState: checkedPlatform, firstLoad: true});
 //            this.setStorage(MultiEnum.one, siteInformation)
 //              .then((ss: any) => {
 //
 //                console.log(ss);
 //
 //                   switch (checkedPlatform){
 //                    case 'android':
 //                      this.banner();
 //                      break;
 //
 //                  }
 //
 //                resolve(siteInformation);
 //              }, error => console.log('set storage error', error));
 //          });
 //        }
 //      }, error => this.multiService.toastService({message: error.message, position: 'middle'}));
 //
 //
 //    });
 //
 //  }


banner(){
  AdMob.initialize({
    requestTrackingAuthorization: true,
  }).then(async t7 => {
    console.log('show banner', t7);
    const options: BannerAdOptions = {
      adId: 'ca-app-pub-7535648491950970/8419621622',
      adSize: BannerAdSize.ADAPTIVE_BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      margin: 0,
      // isTesting: true
      // npa: true
    };
    await AdMob.showBanner(options);
  });
}

  clearStorage() {

    return new Promise(resolve => {

      const store = new Storage();
      store.create().then();

      this.platformDetect.platformCheck().then(checkedPlatform => {

      switch (checkedPlatform) {
        case 'android':
          Preferences.clear().then(() => resolve('Storage cleared'));
          break;
        case 'ios':
          Preferences.clear().then(() => resolve('Storage cleared'))
          break;

        case 'mobileweb':
          store.clear().then(() => resolve('Storage cleared'))
          break;

        case 'desktop':
          store.clear().then(() => resolve('Storage cleared'))
          break;
      }
    });

    })

  }
}
