export const environment = {
  production: true,
  useEmulators: false,
  firebase: {
    apiKey: 'AIzaSyBJwiGww1ju8-Pxv70sbqqscjYd_p7GTwA',
    authDomain: 'greattechpro.firebaseapp.com',
    projectId: 'greattechpro',
    storageBucket: 'greattechpro.appspot.com',
    messagingSenderId: '574879234125',
    appId: '1:574879234125:web:7e06a7dff77eca320bc404'
  }
};
