import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformDetectService {

  constructor(private platForm: Platform) { }


  platformCheck() {

    return new Promise(resolve => {

    const checkDeviceState = (deviceVal) => {
    let tempAry = [];
    for (const item of deviceVal.data) {
      tempAry = tempAry.concat(this.platForm.platforms().includes(item));
    }
    return !tempAry.includes(false);
    };

    if(checkDeviceState({data: ['desktop']})){
      resolve('desktop');
    }else if(checkDeviceState({data: ['mobileweb']})){
      resolve('mobileweb');
    }else if(checkDeviceState({data: ['android', 'hybrid']})){
      resolve('android');
    }else if(checkDeviceState({data: ['ios', 'hybrid']})){
      resolve('ios');
    }

    });


  }


}
