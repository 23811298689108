import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-seeyou',
  templateUrl: './seeyou.component.html',
  styleUrls: ['./seeyou.component.scss'],
})
export class SeeyouComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {}

  reEnter() {
    this.router.navigateByUrl('/tabs/Store', {replaceUrl: true}).then()
  }
}
