import {Injectable} from '@angular/core';
import {PlatformDetectService} from './platform-detect.service';
import {Animation, StatusBar, Style} from '@capacitor/status-bar';

@Injectable({
  providedIn: 'root'
})
export class StatusBarService {

  constructor(private platformDetect: PlatformDetectService) {}

  async statusBarAction(param: { state: string; data?: any }) {

    return new Promise(async (resolve, reject) => {

      this.platformDetect.platformCheck().then(checkedPlatform => {

        if (checkedPlatform === 'android') {

          switch (param.state) {
            case 'overlay':
              StatusBar.setOverlaysWebView({overlay: true}).then(t => {
              });
              resolve('final');
              break;
            case 'hide':
              StatusBar.hide({animation: Animation.None}).finally(async () => {
                resolve('final');
              });
              break;
            case 'show':
              StatusBar.show({animation: Animation.None}).then(async t => {
                await StatusBar.setOverlaysWebView({overlay: false});
                await StatusBar.setStyle({style: Style.Light});
                await StatusBar.setBackgroundColor({color: '#ffffff'});
              });
              break;

          }
        }
      });
    });

  }
}
