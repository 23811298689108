import {Injectable, Input, resolveForwardRef} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {catchError, map} from 'rxjs/operators';
import {convertSnaps} from '../multi/db-util';
 import firebase from 'firebase/compat/app';
import OrderByDirection = firebase.firestore.OrderByDirection;
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import Timestamp = firebase.firestore.Timestamp;
import {getHostName} from '../multi/multiFunctions';
import {SiteInformationModel} from '../multi/multi-model';
 import { SiteInformationClass} from '../multi/multi-class';
import {AngularFireStorage} from "@angular/fire/compat/storage";

@Injectable({
  providedIn: 'root'
})

export class QueryService {


  siteInformation: SiteInformationModel =  new SiteInformationClass().siteInformation();

  dbPrefix = null;
  batch = null;

  limit = 20;

  tempAry = [];


  constructor(private angularFireStore: AngularFirestore,
              private angularFireStorage: AngularFireStorage) {

       this.batch  = this.angularFireStore.firestore.batch();
      this.dbPrefix = this.angularFireStore.collection(`/${getHostName()}`);

      // this.dbPrefix = this.angularFireStore.collection(`/rtxlaptopstore`);




   }



  getDocWithSnapshot(docId) {
    console.log(this.dbPrefix, docId)
    return new Observable(observer => {
      this.dbPrefix.doc(docId)
        .snapshotChanges().subscribe(s => {
          console.log(s)
        observer.next({...s.payload.data(), id: s.payload.id});
      });
    });

  }

  updateDoc(docName, data) {
    return new Observable(observer => {
      this.dbPrefix.doc(docName)
        .update(data).then(t => {
        observer.next(true);
      }).catch(c => {
        observer.next(false);
      });

    });

  }

  addData(param: { data: any; docName: string; collection: string; docId?: any }) {

    if (param.docId){
      return new Promise((resolve, reject) => {
        this.dbPrefix.doc(param.docName).collection(param.collection).doc(param.docId)
          .set(Object.assign(param.data, { timeStamp: Timestamp.now().seconds.toString()})).then(t => resolve(true)).catch(c => reject(c));
      });
    }

    return new Promise((resolve, reject) => {
      this.dbPrefix.doc(param.docName).collection(param.collection)
        .add(Object.assign(param.data, { timeStamp: Timestamp.now().seconds.toString()})).then(t => resolve(t)).catch(c => reject(c));
    });
  }


  getData(param: { data: any; collection: string; docName: string }): Observable<any> {

    switch (param.data.queryCount) {
      case 1:
        return this.dbPrefix.collection(`${param.docName}/${param.collection}`, ref =>
          ref.where(param.data.one.key, '==', param.data.one.value))
          .get()
          .pipe(
            map(result => convertSnaps(result)),
            catchError(err => throwError(err)));
      case 2:
        return this.dbPrefix.collection(`${param.docName}/${param.collection}`, ref =>
          ref.where(param.data.one.key, '==', param.data.one.value)
            .where(param.data.two.key, '==', param.data.two.value))
          .get()
          .pipe(
            map(result => convertSnaps(result)),
            catchError(err => throwError(err)));
    }
  }

  setDoc(collectionName, docName, data: any) {
    return new Observable(observer => {


      const ref = this.dbPrefix.doc(docName).ref;

      this.batch.set(ref, data);

      this.batch.commit().then(t => observer.next(t));


    });


  }

  getDataCollectionGroup(param: {
    collectionGroup: string; orderBy: any;
    data: { one: { value: any; key: string };
      two?: { value: any; key: string };
      queryCount: number;
      take: number; }; }) {

    switch (param.data.queryCount) {
      case 1:
        return this.angularFireStore.collectionGroup(param.collectionGroup, ref =>
          ref.where(param.data.one.key, '==', param.data.one.value)
            .orderBy('timeStamp', param.orderBy).limit(param.data.take))
          .get()
          .pipe(
            map(result => convertSnaps(result)),
            catchError(err => throwError(err)));

      case 2:
        return this.angularFireStore.collectionGroup(`${param.collectionGroup}`, ref =>
          ref.where(param.data.one.key, '==', param.data.one.value)
            .where(param.data.two.key, '==', param.data.two.value)
            .orderBy('timeStamp', param.orderBy).limit(param.data.take))
          .get()
          .pipe(
            map(result => convertSnaps(result)),
            catchError(err => throwError(err)));
    }
  }

  getDataCollectionGroupWithPagination(param: { collectionGroup: string; data: { limit: number; startAfter: any; one: { value: any; key: string; opStr: WhereFilterOp };
      two?: { value: any; key: string; opStr: WhereFilterOp }; queryCount: number; }; orderBy: OrderByDirection; }) {

    console.log(param);

    switch (param.data.queryCount) {
      case 1:

        if (!param.data.startAfter){
          return this.angularFireStore.collectionGroup(param.collectionGroup, ref =>
            ref.where(param.data.one.key,
              param.data.one.opStr
              , param.data.one.value)
              .orderBy('timeStamp', param.orderBy)
              .limit(param.data.limit))
            .get()
            .pipe(
              map(result => convertSnaps(result)),
              catchError(err => throwError(err)));
        }


        return this.angularFireStore.collectionGroup(param.collectionGroup, ref =>
          ref.where(param.data.one.key, '==', param.data.one.value)
            .orderBy('timeStamp', param.orderBy)
            .limit(param.data.limit)
            .startAfter(param.data.startAfter))
          .get()
          .pipe(
            map(result => convertSnaps(result)),
            catchError(err => throwError(err)));
    }
  }

  getDocOnce(docName) {
    return new Observable(observer => {
      this.dbPrefix.doc(docName)
        .get().subscribe(s => {
        observer.next(s.data());
      });
    });
  }

  getProductAry(param) {

    console.log(param)

     return new Promise((resolve, reject) => {
      this.getDataCollectionGroupWithPagination(param).subscribe((s: any) => {
           resolve(s);

      }, error => reject(error));
    });
  }

  async deleteStorageEachDataByUrl(url) {

    console.log(url)

    return new Promise((resolve, reject) => {
      this.angularFireStorage.storage.refFromURL(url).delete().then(d => {
        console.log('Deleted');
        resolve('Image deleted')
      }).catch(() => reject('Image delete rejected!'));
    })

  }

  deleteDoc(urlAndId) {

    console.log(urlAndId)

    return new Promise((resolve, reject) => {
      this.dbPrefix.doc(urlAndId).delete()
        .then(t => resolve('Deleted'))
        .catch(c => reject('Delete rejected'))
    })


  }
}
