import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {UserGuard} from './user.guard';
import {SeeyouComponent} from "./component/seeyou/seeyou.component";
import {AdminGuard} from "./admin.guard";

const routes: Routes = [

  { path: '', redirectTo: 'tabs/Store', pathMatch: 'full' },

  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'admin',
    // loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule), canActivate: [UserGuard, AdminGuard],
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  },

  {
    path: 'developer',
    loadChildren: () => import('./developer/developer.module').then( m => m.DeveloperPageModule)
  },
  {
    path: 'testing',
    loadChildren: () => import('./testing/testing.module').then( m => m.TestingPageModule)
  },{
    path: 'seeyou',
   component: SeeyouComponent
  },
  {
    path:'**',
    redirectTo: 'tabs/Store',
    pathMatch: 'full'
  },



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
