import { Injectable } from '@angular/core';
import {SiteInformationClass} from '../multi/multi-class';
import {SiteInformationModel} from '../multi/multi-model';
import {getHostName} from '../multi/multiFunctions';
import {MultiEnum} from '../multi/multi-enum';
import {QueryService} from './query.service';
import {PlatformDetectService} from './platform-detect.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {StorageService} from "./storage.service";


@Injectable({
  providedIn: 'root'
})

export class TempService {

  siteInformation: SiteInformationModel = new SiteInformationClass().siteInformation();

  constructor(
    private angularFire: AngularFireAuth, private storageService: StorageService,
    private queryService: QueryService, private platformDetect: PlatformDetectService,
   ) {
    console.log('temp service start');
     console.log('temp service passed method');

    this.platformDetect.platformCheck().then(checkedPlatform => {
      Object.assign(this.siteInformation, {hostName: getHostName(), deviceState: checkedPlatform});
    })

        this.firstLoad().then();

  }

  async firstLoad() {
    console.log(getHostName());

    console.log('first load calling...');
    this.queryService.getDocWithSnapshot(MultiEnum.one)
      .subscribe((siteInformation: SiteInformationModel) => {
          console.log(siteInformation);

          if (siteInformation) {

            Object.assign(siteInformation, {loaded: true})

            for (const key of Object.keys(siteInformation)) {
              console.log(key)
              if (key !== 'deviceState'){
                Object.assign(this.siteInformation, {[key]: siteInformation[key]})
              }
            }



            // this.platformDetect.platformCheck().then(checkedPlatform => {
            //   Object.assign(siteInformation, {hostName: getHostName(), deviceState: checkedPlatform, firstLoad: true});
            //  this.siteInformation = siteInformation;
              // this.siteInform.next(siteInformation)
              //   this.setStorage(MultiEnum.one, siteInformation)
              //     .then((ss: any) => {
              //
              //       console.log(ss);
              //
              //       switch (checkedPlatform){
              //         case 'android':
              //           this.banner();
              //           break;
              //
              //       }
              //
              //       resolve(siteInformation);
              //     }, error => console.log('set storage error', error));
            // });
          }
        }, error => {
        }
        // this.multiService.toastService({message: error.message, position: 'middle'}));
      );
  }
}
