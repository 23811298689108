import {MultiEnum} from './multi-enum';




export class SiteInformationClass {



  siteInformation = () => ({
    loaded: false,
       deviceState: null,
      header: {
      showPhoto: false,
        photoUrl: '',
        background: MultiEnum.white,
        headerText: {name: null, title: null, nameColor: MultiEnum.black, titleColor: MultiEnum.black}
      },
      storePage : {
        label1: {text: null, color: MultiEnum.black, background: MultiEnum.white},
        label2: {text: null, color: MultiEnum.black, background: MultiEnum.white},
        label3: {text: null, color: MultiEnum.black, background: MultiEnum.white},
        label4: {text: null, color: MultiEnum.black, background: MultiEnum.white},
        label5: {text: null, color: MultiEnum.black, background: MultiEnum.white},
      },
      categories :
        [{
          searchBtn: false, addBtn: false, names: [], categoryName: 'Main',
          relationName: null, uploadStatus: false, selectPhoto: null, relationId: null, name: null
        },
          {
            searchBtn: false, addBtn: false, names: [], categoryName: 'Sub1',
            relationName: null, uploadStatus: false, selectPhoto: null, relationId: null, name: null
          },
          {
            searchBtn: false, addBtn: false, names: [], categoryName: 'Sub2',
            relationName: null, uploadStatus: false, selectPhoto: null, relationId: null, name: null
          }
        ],
      categoryData: [],
      userData: {name: null, phone: null, id: null, role: [], profileImg: null, coverImg: null},
      selectPhoto: null,
      specification: [],
      addProductForm: {
        mainPic: null
      },
      forms: {},
      hostName: null,
    storeSlidePhotos: []
    });
}

export class MultiClass {


  // [MultiEnum.one] = {
  //   deviceState: null,
  //    header: {
  //     background: MultiEnum.white,
  //     headerText: {name: null, title: null, nameColor: MultiEnum.black, titleColor: MultiEnum.black},
  //   },
  //   storePage: {
  //     label1: {text: null, color: MultiEnum.black, background: MultiEnum.white},
  //     label2: {text: null, color: MultiEnum.black, background: MultiEnum.white},
  //     label3: {text: null, color: MultiEnum.black, background: MultiEnum.white},
  //     label4: {text: null, color: MultiEnum.black, background: MultiEnum.white},
  //     label5: {text: null, color: MultiEnum.black, background: MultiEnum.white},
  //   },
  //   categories:
  //     [  {searchBtn: false, addBtn: false, names: [], categoryName: 'Main',
  //       relationName: null, uploadStatus: false, selectPhoto: null, relationId: null, name: null},
  //        { searchBtn: false, addBtn: false, names: [], categoryName: 'Sub1',
  //          relationName: null, uploadStatus: false, selectPhoto: null, relationId: null, name: null},
  //        { searchBtn: false, addBtn: false, names: [], categoryName: 'Sub2',
  //          relationName: null, uploadStatus: false, selectPhoto: null, relationId: null, name: null}
  //     ],
  //   categoryData: [],
  //   categoryRelationName: {
  //      mainRelationId: 'Main',
  //      sub1RelationId: null,
  //     sub2RelationId: null
  //   },
  //
  //   userData: {name: null, phone: null, uid: null},
  //   selectPhoto: null,
  //   specification: [],
  //   addProductForm: {
  //      mainPic: ''
  //   },
  //   forms: {},
  //   hostName: null
  // };

  registerFormObj = [
    {name: 'name', type: 'text', placeholder: 'Your name'},
    {name: 'phone', type: 'number', placeholder: 'Phone number'},
    {name: 'verificationCode', type: 'number', placeholder: 'Verification code'},
    {name: 'password', type: 'password', placeholder: 'Password'},
    {name: 'confirm_password', type: 'password', placeholder: 'Confirm password'},
  ];

  loginFormObj = [
    {name: 'phone', type: 'number', placeholder: 'Phone number'},
    {name: 'password', type: 'password', placeholder: 'Password'},
  ];

  forgetFormObj = [
    {name: 'phone', type: 'number', placeholder: 'Phone number'},
    {name: 'verificationCode', type: 'number', placeholder: 'Verification code'},
    {name: 'password', type: 'password', placeholder: 'Update password'},
  ];


}
