export enum MultiEnum {

  one = 'siteInformation',
  two = 'userInfo',

  black = '#000000',
  white = '#ffffff'

}


