import {Injectable} from '@angular/core';
import {AlertController, LoadingController, ModalController, Platform, ToastController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {MultiEnum} from './multi-enum';
import {catchError, concatMap, last, tap} from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import {SiteInformationModel} from './multi-model';
import {MultiClass} from './multi-class';
import {StatusBarService} from '../services/status-bar.service';
import {App} from '@capacitor/app';
import {NgxImageCompressService} from 'ngx-image-compress';
import Timestamp = firebase.firestore.Timestamp;
import imageCompression from 'browser-image-compression';
import getFilefromDataUrl = imageCompression.getFilefromDataUrl;

@Injectable({
  providedIn: 'root'
})

export class MultiService {

  subscription = new Subscription();

  siteInformation: SiteInformationModel = new MultiClass()[MultiEnum.one];
  alertMessageValue = {header: null, message: null, acceptBtnName: 'Yes', cancelBtnName: 'No', cssClass: null};


  proxy = 'https://queen.luchawlaysannko.workers.dev/?';


  constructor(private alertController: AlertController,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private angularFireStore: AngularFirestore,
              private angularFireStorage: AngularFireStorage,
              private angularFireAuth: AngularFireAuth,
              private modalCtrl: ModalController,
              private platform: Platform,
              private loadingController: LoadingController,
              private toastController: ToastController,
              private httpClient: HttpClient,
              private statusBarService: StatusBarService,
              private ngxImageCompress: NgxImageCompressService) {
  }


  async modal(param: { component: any; data: any }) {
    const modalCtrl = await this.modalCtrl.create({
      component: param.component,
      componentProps: {props: param.data},
      animated: true,
      showBackdrop: true,
    });

    modalCtrl.onWillDismiss().then(t => {
      this.statusBarService.statusBarAction({state: 'show'}).then();
      if (param.data.backUrl) {
        // history.replaceState(null, null, param.data.backUrl);
        // history.pushState(null, null, param.data.backUrl);
      }
    });

    if (param.data.currentUrl) {
      // history.replaceState(null, null, param.data.currentUrl);
      // history.pushState(null, null, param.data.currentUrl);
    }

    await modalCtrl.present();
  }

  async alertService(state) {

    console.log('alert invoke');

    switch (state) {
      case 'exit':
        Object.assign(this.alertMessageValue, {
          header: 'Hello user!',
          message: 'Are you sure to exit?',
          cancelBtnName: 'No',
          acceptBtnName: 'Yes',
          cssClass: '',
        });
        break;
      case 'AddProduct':
        Object.assign(this.alertMessageValue, {
          header: 'Successfully uploaded',
          message: 'You are working done',
          cancelBtnName: 'To product list',
          acceptBtnName: 'Next upload',
          cssClass: '',
          backdropDismiss: true
        });
        break;
      case 'DeletePost':
        Object.assign(this.alertMessageValue, {
          header: 'Post deleting!',
          message: 'This are you sure to delete?',
          cancelBtnName: 'No',
          acceptBtnName: 'Yes',
          cssClass: '',
          backdropDismiss: true
        });
        break;
    }


    return new Promise(async (resolve, reject) => {

      const alertCtrl = await this.alertController.create({
        ...this.alertMessageValue,
        buttons: [
          {
            role: 'cancel',
            text: this.alertMessageValue.cancelBtnName,
            handler: value => {
              resolve(false);
            }
          },
          {
            text: this.alertMessageValue.acceptBtnName,
            handler: value => {
              resolve(true);
            }
          }
        ]
      });

      alertCtrl.onDidDismiss().then(t => {

        // history.replaceState(null, null, backUrl);
        // history.pushState(null, null, backUrl);
      });

      // history.replaceState(null, null, currentUrl);
      // history.pushState(null, null, currentUrl);

      // if (window.history.state && !window.history.state.alert) {
      //   const alertState = {alert: true};
      //   window.history.pushState(alertState, null);
      // }


      await alertCtrl.present();


    });
  }


  async setUrl(param: { url: string }) {
    await this.router.navigateByUrl(param.url);
  }

  routeEvent() {
    return new Observable(observer => this.router.events.subscribe((s: any) => {
      observer.next(s);
    }));
  }

  activatedRouteData() {
    return new Observable(observer => {
      this.activatedRoute.params.subscribe(s => {
        observer.next(s);
      });
    });
  }


  activatedFragment() {
    return this.activatedRoute.fragment;
  }

  getSetTime() {
    return new Date().getTime().toString();
  }


  // getDocOnce(docName: string) {
  //   return new Observable(observer => {
  //     this.angularFireStore.doc(`${this.dbPrefix}/` + docName)
  //       .get().subscribe(s => observer.next(s));
  //   });
  //
  // }

  requestHttp(param: { method: string; url: string; param?: HttpParams }) {

    return new Observable(observer => {

      switch (param.method) {
        case 'get':
          this.httpClient.get(this.proxy + param.url)
            .subscribe(s => observer.next(s), error => observer.error(error));
          break;

        case 'post':
          this.httpClient.post(this.proxy + param.url, {}, {params: param.param})
            .subscribe(s => observer.next(s), error => observer.error(error));
          break;

      }
    });

  }


  addPhoto(param: { folderName: string; subFolderName: any; data: any }) {
    const fileList = param.data;
    const tempUrl = [];


    return new Observable(observer => {
      for (const file of fileList) {
        const filePath = param.folderName + '/' + param.subFolderName + '/' + file.file.name;
        const task = this.angularFireStorage.upload(filePath, file.file, {
          cacheControl: 'max-age=2592000,public',
        });

        task.percentageChanges().subscribe(s => {
          // console.log({[file.file.name]: s})
          observer.next({url: null, progress: {[file.file.name]: s}});
        });


        task.snapshotChanges()
          .pipe(last(),
            concatMap(() =>
              this.angularFireStorage.ref(filePath).getDownloadURL()),
            tap(url => {
              tempUrl.push({url});
              if (param.data.length === tempUrl.length) {
                observer.next({url: tempUrl, progress: null});
              }
            }),
            catchError(async (err: any) => {
              alert('Could not create thumbnail url');
            })
          ).subscribe();
      }
    });
  }

  // async logout() {
  //   return new Promise(resolve => {
  //     if (this.siteInformation.deviceState === 'desktop') {
  //       const storage = new Storage();
  //       storage.create().then(t0 => {
  //         delete this.siteInformation.userData;
  //         storage.remove(MultiEnum.one).then(t3 => {
  //           resolve(true);
  //         });
  //       });
  //     }
  //     if (this.siteInformation.deviceState === 'android' || 'ios') {
  //       this.getStorageData(MultiEnum.one).then((data: SiteInformationModel) => {
  //         if (data && data.userData) {
  //           delete data.userData;
  //         }
  //         this.setStorage(MultiEnum.one, data).then(s => resolve(true));
  //       });
  //     }
  //   });
  //
  //
  // }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  async loadingControl(state, msg = 'Uploading') {

    if (!state) {
      await this.loadingController.dismiss();
      return;
    }

    const loadCtrl = await this.loadingController.create({
      message: msg,
      backdropDismiss: false

    });

    await loadCtrl.present();


  }

  createCollectionId() {
    return this.angularFireStore.createId();
  }

  getTimeStamp() {
    return Timestamp.now().seconds.toString();
  }

  getPhoto(val) {

    console.log('get photo work');

    return new Observable(observer => {

      //   let state =  'pickImages'
      //   const cameraOption = {
      //     quality: 80,
      //     limit: 10,
      //     source: CameraSource.Photos,
      //   }
      //
      //   if (val === 'Camera') {
      //     state = 'getPhoto';
      //     Object.assign(cameraOption, {resultType: CameraResultType.Uri, source: CameraSource.Camera})
      //   }
      //
      //
      //    Camera[state](cameraOption)
      //      .then(t => {
      //        console.log(t)
      //        observer.next(t)
      //      }).finally(() => console.log('finally image select'))

      this.ngxImageCompress.uploadMultipleFilesOrReject().then(image => {
        console.log(image);
        observer.next(image);
      }).catch(c => console.log(c));

    });


  }


  base64ConvertToFile(image) {

    const blob = image.webPath;

    return {
      url: blob, file: new File([blob], new Date().getTime().toString(),
        {
          lastModified: new Date().getTime(),
          type: 'image/jpeg'
        })
    };
  }

  imgPathArrayConvertToFile(img:any) {
    const tempAry = [];

    console.log(img)

    return new Promise(resolve => {

      img.map(async m => {


        console.log(m);

        let imageBase64 =  m.image.replace('data:image/png;base64,', '')
          imageBase64 =  imageBase64.replace('data:image/jpeg;base64,', '')
        imageBase64 =  imageBase64.replace('data:image/jpg;base64,', '')


        const blobFile = await this.base64toBlob(imageBase64, `image/jpeg`);

      await  getFilefromDataUrl(m.image, m.fileName, new Date().getTime())
          .then(async myFile => {
            console.log(myFile);


        console.log(blobFile);

        if (blobFile.size <= 400000) {

          console.log(blobFile);

          console.log(myFile);

          tempAry.push({
            // blobUrl: URL.createObjectURL(m.webPath),
            url: m.image,
            file: myFile
          });

          if (img.length === tempAry.length) {
            resolve(tempAry);
          }
          // });

        }

        if (blobFile.size > 400000) {


          const options = {
            maxSizeMB: 0.4,
            maxWidthOrHeight: 1300,
            useWebWorker: true
          };
          try {
            const compressedFile = await imageCompression(myFile, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

            console.log(compressedFile);


            const myFile2 = new File([compressedFile], m.fileName, {
              type: 'image/jpeg',
            });

            tempAry.push({
              // blobUrl: URL.createObjectURL(m.webPath),
              url: URL.createObjectURL(compressedFile),
              file: myFile2
            });

            if (img.length === tempAry.length) {
              resolve(tempAry);
            }

          } catch (error) {
            console.log(error);
          }

        }

          });
      });
    });
  }

  async fileToBase64($event) {
    const pickedFile = ($event.target as HTMLInputElement).files;
    const tempAry = [];
    return new Promise(async resolve => {
      Object.keys(pickedFile).map(m => {
        const fr = new FileReader();
        fr.onload = () => {
          tempAry.push({url: fr.result, file: pickedFile[m]});
          if (tempAry.length === Object.keys(pickedFile).length) {
            resolve(tempAry);
          }
        };
        fr.readAsDataURL(pickedFile[m]);
      });


    });
  }

  async toastService(param: { message: string; position: any }) {
    const toast = await this.toastController.create({
      position: param.position,
      message: param.message,
      duration: 2000,
    });

    await toast.present();
  }


  exitApp() {


    this.alertService('exit').then(t => {
      if (t) {
        App.removeAllListeners().then();
        App.exitApp().then();
      }
    });


  }


  inscribing() {
    this.subscription.unsubscribe();
  }
}
